import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Tag } from 'antd'; 
import { createClient } from '@supabase/supabase-js';
import './MyTableComponent.css';

// Initialize Supabase Client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);


const courseAttributes = [
  "Independent Study",
  "Seminar",
  "Topics Course",
  "Graded only. S/U not allowed.",
  "Satisfactory/Unsatisfactory Only",
  "Taught at Marine Lab",
  "Taught off campus",
  "Bass Connections",
  "Has service-learning/community-engaged component",
  "Crosslisted in another department",
  "Transformative Ideas",
  "What Now?",
  "(CCI) Cross Cultural Inquiry",
  "(EI) Ethical Inquiry",
  "(FL) Foreign Language",
  "(R) Research",
  "(STS) Science, Technology, and Society",
  "(W) Writing",
  "(ALP) Arts, Literature & Performance",
  "(CZ) Civilizations",
  "(NS) Natural Sciences",
  "(QS) Quantitative Studies",
  "(SS) Social Sciences"
];

// Map course attributes to filter options for the table
const attributeFilters = courseAttributes.map(attribute => ({
  text: attribute,
  value: attribute,
}));

const attributeColors = {
  "Independent Study": "magenta",
  "Seminar": "red",
  "Topics Course": "volcano",
  "Graded only. S/U not allowed.": "orange",
  "Satisfactory/Unsatisfactory Only": "gold",
  "Taught at Marine Lab": "lime",
  "Taught off campus": "green",
  "Bass Connections": "cyan",
  "Has service-learning/community-engaged component": "blue",
  "Crosslisted in another department": "geekblue",
  "Transformative Ideas": "purple",
  "What Now?": "pink",
  "(CCI) Cross Cultural Inquiry": "brown",
  "(EI) Ethical Inquiry": "gray",
  "(FL) Foreign Language": "yellow",
  "(R) Research": "lightblue",
  "(STS) Science, Technology, and Society": "lightgreen",
  "(W) Writing": "lightcoral",
  "(ALP) Arts, Literature & Performance": "lightskyblue",
  "(CZ) Civilizations": "lightpink",
  "(NS) Natural Sciences": "lightseagreen",
  "(QS) Quantitative Studies": "lightsteelblue",
  "(SS) Social Sciences": "darkblue"
};

function findAttributes(courseAttributes, courseAttributesString) {
  const result = [];

  courseAttributes.forEach(attribute => {
    if (courseAttributesString.includes(attribute)) {
      result.push(attribute); // Removed the bullet point prefix for cleaner array elements
    }
  });

  return result; // Return the array of attributes
}




// Main React component for the table
function MyTableComponent() {
  const [data, setData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [filters, setFilters] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [searchQuery, setSearchQuery] = useState('');



  const subjects = [
    'MUSIC', 'JEWISHST', 'AEROSCI', 'PATHOL', 'CEE', 'LATAMER', 'ENVIRON', 'MEDREN', 
    'RIGHTS', 'PORTUGUE', 'NAVALSCI', 'MGM', 'LIT', 'LINGUIST', 'THEATRST', 'RELIGION', 
    'SOCIOL', 'GSF', 'STA', 'HLTHPOL', 'SCISOC', 'EOS', 'BIOLOGY', 'KICHE', 'BME', 
    'JAM', 'NEUROSCI', 'AADS', 'COMPSCI', 'ENGLISH', 'EHD', 'ARABIC', 'VMS', 'EDUC', 
    'BRAINSOC', 'SUSTAIN', 'LATIN', 'KOREAN', 'EGR', 'PHARM', 'PSY', 'UNIV', 'SES', 
    'ICS', 'RACESOC', 'CULANTH', 'CHINESE', 'RUSSIAN', 'ECE', 'FECON', 'NEUROBIO', 
    'CHILDPOL', 'ENRGYEGR', 'MILITSCI', 'ITALIAN', 'AAAS', 'ME', 'PERSIAN', 'PHYSICS', 
    'PHYSEDU', 'ARTS&SCI', 'SPANISH', 'SWAHILI', 'MMS', 'HEBREW', 'WRITING', 'PHIL', 
    'CREOLE', 'MARSCI', 'PUBPOL', 'HOUSECS', 'CLST', 'DANCE', 'ARTSVIS', 'ISS', 'IMMUNOL', 
    'CHEM', 'BIOCHEM', 'ETHICS', 'AMES', 'MATH', 'ECS', 'ARTHIST', 'FRENCH', 'DOCST', 
    'ROMST', 'EVANTH', 'CINE', 'ENERGY', 'GREEK', 'HINDI', 'GERMAN', 'SXL', 'SANSKRIT', 
    'LSGS', 'CELLBIO', 'HISTORY', 'FMKT', 'CMAC', 'TURKISH', 'MALAGASY', 'JPN', 'GLHLTH', 
    'I&E', 'ECON', 'POLSCI'
].sort();

    const subjectFilters = subjects.map(subject => ({ text: subject, value: subject }));
  
    
    const handleWindowSizeChange = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    // Event listener to handle window resize for responsive design
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      };
    }, []);

    const fetchData = async (currentFilters) => {
      let fetchedData = [];
      let offset = 0;
      const limit = 1000; // Adjust this limit as needed
  
      let query = supabase.from('classes_complete').select('*');
  
      Object.keys(currentFilters).forEach((filterKey) => {
        const filterValue = currentFilters[filterKey];
        if (filterValue) {
          query = query.ilike(filterKey, `%${filterValue}%`);
        }
      });


      

  
      while (true) {
        const { data: classesData, error } = await query.range(offset, offset + limit - 1);
  
        if (error) {
          console.error('Error fetching data:', error);
          break;
        }
  
        if (classesData && classesData.length) {
          fetchedData = [...fetchedData, ...classesData];
          offset += limit;
        } else {
          break;
        }
      }

              fetchedData = fetchedData.map(entry => ({
          ...entry,
          class_attributes: entry.class_attributes ? findAttributes(courseAttributes, entry.class_attributes) : [], // Check if class_attributes is not null or undefined
        }));
  
      setData(fetchedData);
    };





    const handleFilterChange = (pagination, filters, sorter) => {
      const newFilters = {
        ...filters, 
      };

      // Process each filter and update state
      Object.keys(filters).forEach((key) => {
        const filterValue = filters[key];
        if (filterValue && filterValue.length > 0) {
          newFilters[key] = filterValue[0]; // Taking the first selected filter value for simplicity
        } else {
          delete newFilters[key];
        }
      });
    
      setFilters(newFilters); 
      fetchData(newFilters); 
    };

    const handleSearch = (value) => {
      setSearchQuery(value);
    
      const newFilters = {
        ...filters,
        descr: value ? value : null  
      };
    
      setFilters(newFilters); 
      fetchData(newFilters); 
    };

    const handleReset = () => {
      setSearchQuery('');  
      setFilters({});     
      fetchData({});       
    };

  const columns = [
    {
        title: 'Course Number',
        dataIndex: 'catalog_nbr',
        width: isMobile ? 150 : 150,
        sorter: (a, b) => a.catalog_nbr.localeCompare(b.catalog_nbr)
      },
      {
        title: 'Department',
        dataIndex: 'subject',
        filters: subjectFilters,
        filterSearch: true,
        width: isMobile ? 150 : 150,
        onFilter: (value, record) => record.subject === value,
      },
    {
        title: 'Class Name',
        dataIndex: 'descr',
        width: isMobile ? 550 : 300,
      },
    {
      title: 'Class Size',
      dataIndex: 'class_capacity',
      width: isMobile ? 150 : 100,
      sorter: (a, b) => a.class_capacity - b.class_capacity,
    },
    {
        title: 'Instructor',
        dataIndex: 'first_name',  // dataIndex can be any one of them, since we are overriding the render
        key: 'full_name',         // Optional but can be helpful
        width: isMobile ? 150 : 200,
        render: (text, record) => `${record.first_name} ${record.last_name}`
      },
      
      {
        title: 'Days',
        dataIndex: 'days',
        filters: [
          { text: 'Mo', value: 'Mo' },
          { text: 'Tu', value: 'Tu' },
          { text: 'We', value: 'We' },
          { text: 'Th', value: 'Th' },
          { text: 'Fr', value: 'Fr' },
          { text: 'MoWe', value: 'MoWe' },
          { text: 'TuTh', value: 'TuTh' },
          { text: 'MoWeFr', value: 'MoWeFr' },
          { text: 'TuThFr', value: 'TuThFr' },
        ],
        width: 150,
        onFilter: (value, record) => record.days === value,
      },
      
    {
        title: 'Class Time',
        dataIndex: 'start_time',
        key: 'class_time',
        width: 150,
        render: (text, record) => {
          if (record.start_time === 0 || record.start_time === undefined || record.start_time === null) {
            return 'NA';
        }
          const formatTime = (time) => {
            const match = time.match(/^(\d{2}):(\d{2})/);
            if (match) {
              const hour = parseInt(match[1], 10);
              const minute = match[2];
              const amOrPm = hour < 12 ? 'AM' : 'PM';
              const formattedHour = hour > 12 ? hour - 12 : hour; // Convert to 12-hour format
              return `${formattedHour}:${minute} ${amOrPm}`;
            }
            return time;
          };
      
          const startTimeFormatted = formatTime(record.start_time);
          const endTimeFormatted = formatTime(record.end_time);
          return <span style={{ whiteSpace: 'nowrap' }}>{`${startTimeFormatted} - ${endTimeFormatted}`}</span>;
        },
      },     
      {
        title: 'Would Take Again',
        dataIndex: 'wouldTakeAgainPercent',
        width: isMobile ? 150 : 150,
        sorter: (a, b) => a.wouldTakeAgainPercent - b.wouldTakeAgainPercent,
        render: (value) => {
          // If the value is -1 or not present, return 'NA'
          if (value === -1 || value === undefined || value === null) {
            return 'NA';
          }
          // Convert the value to an integer to remove any decimal points
          const intValue = parseInt(value, 10);
          // Return the integer value followed by a percent sign
          return `${intValue}%`;
        },
      },      
    {
      title: 'Avg Difficulty',
      dataIndex: 'avgDifficulty',
      width: isMobile ? 150 : 150,
      sorter: (a, b) => a.avgDifficulty - b.avgDifficulty,
      render: (value) => {
        // If the value is -1 or not present, return 'NA'
        if (value === 0 || value === undefined || value === null) {
          return 'NA';
        }
        // Otherwise, return the value followed by a percent sign
        return `${value}`;
      },
    },
    {
      title: 'Avg Rating',
      dataIndex: 'avgRating',
      width: isMobile ? 150 : 150,
      sorter: (a, b) => a.avgRating - b.avgRating,
      render: (value) => {
        // If the value is -1 or not present, return 'NA'
        if (value === 0 || value === undefined || value === null) {
          return 'NA';
        }
        // Otherwise, return the value followed by a percent sign
        return `${value}`;
      },
    },
    {
      title: 'Attributes',
      width: isMobile ? 150 : 150,
      dataIndex: 'class_attributes',
        filters: attributeFilters,
        onFilter: (value, record) => {
          return record.class_attributes.includes(value);
        },
      // ... (other properties)
      render: classAttributes => (
        <>
          {classAttributes.map((attribute) => {
            const color = attributeColors[attribute] || 'default'; // Fallback to 'default' color if not mapped
            return (
              <Tag className="custom-tag" color={color} key={attribute}>
                {attribute}
              </Tag>
            );
          })}
        </>
      ),
    }
  ];


  useEffect(() => {
    fetchData(filters);
  }, []);

  function ProfessorReviewLink({ record }) {
    return (
      <>
        {record.legacyId && (
          <a href={`https://www.ratemyprofessors.com/professor/${record.legacyId}`}>Read Professor Reviews</a>
        )}
      </>
    );
  }

  
  // JSX for rendering the table and associated components
  return (
    <>
    <Input.Search
        placeholder="Search Class Name"
        onSearch={handleSearch}
        style={{ marginBottom: 16, width: 200, marginRight: 5 }}
      />
    <Button onClick={handleReset}>Reset</Button>
    <Table
      columns={columns}
      dataSource={data}
      rowKey="class_nbr"  // Use class_nbr as the unique key for each row
      expandedRowKeys={expandedRowKeys}  // Control which rows are expanded
      onExpand={(expanded, record) => {  // Logic for handling row expansion
        if (expanded) {
          setExpandedRowKeys([record.class_nbr]);
        } else {
          setExpandedRowKeys([]);
        }
      }}
      onChange={handleFilterChange}
      expandable={{
        expandedRowRender: record => (
          <div>
            {record.topic ? (
                <>
                <h4>Topic</h4>
                <p>{record.topic}</p>
                </>
            ) : null}
            <h4>Class Type</h4>
            <p>{record.component}</p>
            <h4>School</h4>
            <p>{record.acad_career_descr}</p>
            <h4>Description</h4>
            <p>{record.descr}</p>
            <h4>Enroll Requirements</h4>
            <p>{record.enroll_requirements}</p>
            <h4>Class Attributes</h4>
            <div>
            {record.class_attributes.map((attribute, index) => (
              <Tag color={attributeColors[attribute] || 'default'} key={index}>
                {attribute}
              </Tag>
            ))}
          </div>
            <h4>Location</h4>
            <p>{record.facility_descr}</p>
            <ProfessorReviewLink record={record} />
          </div>
        ),
      }}
      pagination={{
        pageSize: 100,
      }}
      className="my-custom-table" // add this line
      sticky // add this line
      scroll={{ x: 'max-content', y: 'calc(100vh - 100px)' }}
    />
    </>
  );
  
}

export default MyTableComponent;
