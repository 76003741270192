import React from 'react';
import './App.css';
import MyTableComponent from './components/MyTableComponent';
import logo from './backpacklogo.png';  // Assuming you have a logo in your src folder named logo.png
import ReactGA from 'react-ga4';
import Chatbot from './components/Chatbot';


ReactGA.initialize("G-291J4D6LN0");

ReactGA.send({ hitType: "pageview", page: "/index", title: "index" });

// const isMobile = window.innerWidth <= 768;


function App() {

  // if (isMobile) {
  //   return (
  //     <div className="App">
  //       <header className="App-header">
  //         <h1>Please use a computer</h1>
  //         <p className="description">This website is best viewed on a desktop or laptop. Please switch to a computer for the best experience.</p>
  //       </header>
  //     </div>
  //   );
  // }
  
  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <img src={logo} alt="Better Bookbagging Logo" className="logo" />
        </div>
        <h1 className="title">Better Bookbagging</h1>
        <p className="description">
         Tired of falling asleep in boring lectures? Want to get an easy A? Find the best classes based on your peers' feedback.
        </p>
        <h2>Here's how it works:</h2>
        <p className="description">
         Simply pick your favorite Duke departments then sort Spring 2024 classes based on the criteria you care about.
        </p>
      </header>
      <main>
        <MyTableComponent/>
        <div className="chatbot-container">
            <Chatbot/>
        </div>  
      </main>
    </div>
  );
}

export default App;
