// Import necessary React hooks and components from 'react' and 'antd' library
import React, { useState, useEffect } from 'react';
import { List, Input, Button } from 'antd';
import './Chatbot.css';

// ChatbotPopup component definition with props
const ChatbotPopup = ({ isOpen, togglePopup, conversation, question, setQuestion, askDukeCourseCatalog, isLoading }) => {
    // Render nothing if the popup is not open
    if (!isOpen) return null;

    return (
        <div className="chatbot-popup">
            <div className="chat-content">
                <List
                    dataSource={conversation}
                    renderItem={item => (
                        <List.Item className={`message ${item.sender}`}>
                            {item.text}
                        </List.Item>
                    )}
                />
            </div>
            <div className="input-container">
                <Input 
                    type="text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder="Ask about Duke courses"
                    onPressEnter={askDukeCourseCatalog}
                    name="question"
                />
                <Button 
                    onClick={askDukeCourseCatalog} 
                    loading={isLoading} 
                    className="ask-button"
                >
                    {isLoading ? "" : "Ask"}
                </Button>
            </div>
            <Button onClick={togglePopup} className="close-button">
                X
            </Button>
        </div>
    );
};

// State for managing user question, conversation, loading status, and popup visibility
function Chatbot() {
    const [question, setQuestion] = useState('');
    const [conversation, setConversation] = useState([{ sender: 'ai', text: 'How can I help?' }]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    useEffect(() => {
        console.log('Chatbot mounted');
        return () => {
            console.log('Chatbot unmounted');
        };
    }, []);

    // Helper function to compile conversation context for the API
    const compileConversationContext = () => {
        return conversation.map(msg => `${msg.sender}: ${msg.text}`).join("\n");
    };

    const askDukeCourseCatalog = async () => {
        const url = 'https://backendchatbot-two.vercel.app/api/chat';
        const conversationContext = compileConversationContext();
    
        // console.log("Conversation Context:", conversationContext);
    
        const data = {
            question: question,
            context: conversationContext || "Hi, I'm the Duke Course Catalog. Let's Chat!"
        };
    
        // console.log("Sending Data:", data);
    
        setConversation([...conversation, { sender: 'user', text: question }]);
        setQuestion('');
        try {
            setIsLoading(true);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });
    
            if (response.ok) {
                const responseData = await response.json();
                const newAnswer = responseData.answer || 'No answer received.';
    
                // console.log("Response Data:", responseData);
    
                setConversation([...conversation, { sender: 'user', text: question }, { sender: 'ai', text: newAnswer }]);
            } else {
                setConversation([...conversation, { sender: 'error', text: `Error: ${response.statusText}` }]);
            }
        } catch (error) {
            setConversation([...conversation, { sender: 'error', text: `Error: ${error.message}` }]);
        } finally {
            setIsLoading(false);
            const chatContent = document.querySelector('.chat-content');
            if (chatContent) {
                chatContent.scrollTop = chatContent.scrollHeight;
            }
        }
    };
    

    return (
        <div>
            {!isPopupOpen && (
                <Button onClick={() => setIsPopupOpen(true)} className="chatbot-tab">
                    Click to get chatbot assistance
                </Button>
            )}
            <ChatbotPopup 
                isOpen={isPopupOpen}
                togglePopup={() => setIsPopupOpen(false)}
                conversation={conversation}
                question={question}
                setQuestion={setQuestion}
                askDukeCourseCatalog={askDukeCourseCatalog}
                isLoading={isLoading}
            />
        </div>
    );
}

export default Chatbot;
